import './Footer.css';
import { usePageContext } from '../../Contexts/PageContext/PageContext';

const Footer = ({ resetDaySelection }) => {
    const { currentPage, handlePageChange } = usePageContext();

    const homeButton = () => {
        return <div className="footer-button" onClick={homeButtonFunctionality}>
            <span className="material-symbols-outlined">
                home
            </span>
        </div >;
    }

    {/* return <div className="footer-button" onClick={() => handlePageChange('profile')}></div> */ }
    const userProfileButton = () => {
        return <div className="footer-button">
            <span className="material-symbols-outlined">
                account_circle
            </span>
        </div >;
    }

    const homeButtonFunctionality = () => {
        switch (currentPage) {
            case "home":
                return resetDaySelection();
                break;
            case "profile":
                return handlePageChange('home');
                break;
            default:
                return handlePageChange('home');
                break;
        }
    }

    const renderedButtons = () => {
        switch (currentPage) {
            case "home":
                return <div className="footer-navigation">
                    {homeButton()}
                    <div></div>
                    {/* {addNomButton()} */}
                    {userProfileButton()}
                    {/* <div></div> */}
                </div>;
                break;
            case "profile":
                return <div className="footer-navigation">
                    {homeButton()}
                    <div></div>
                    {userProfileButton()}
                </div>;
                break;
            default:
                return <div className="footer-navigation">
                    {homeButton()}
                    <div></div>
                    {userProfileButton()}
                </div>;
                break;
        }
    }

    return (
        renderedButtons()
    );
};

export default Footer;

