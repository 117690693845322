import React from 'react';

const DayCarouselItem = ({ day, dayNum, today, onClick, currentMonth }) => {
    // Add the 'greyed-out' class if currentMonth is false
    const classes = 'day-carousel-item ' + (today ? 'day-carousel-item-today ' : '') + (currentMonth ? '' : 'greyed-out');

    return (
        <div className={classes} onClick={onClick}>
            <div className={today ? "day-carousel-item-date day-carousel-item-today-text" : "day-carousel-item-date"} >
                <h1>{day}</h1>
                <span>{dayNum}</span>
            </div >
        </div >
    );
};

export default DayCarouselItem;
