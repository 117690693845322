import React from 'react'
import './NavigationArrow.css'

const NavigationArrow = ({ direction, onClick }) => {

    return (
        <div className="day-carousel-arrow day-carousel-arrow-left" onClick={onClick}>
            {/* Left arrow */}
            <span className="material-symbols-outlined">
                {direction === 'left' ? 'arrow_back' : 'arrow_forward'}
            </span>
        </div>
    )
}

export default NavigationArrow