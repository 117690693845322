import React, { createContext, useContext, useReducer } from 'react';

// Define the ModalContext
const ModalContext = createContext();

// Initial state for the Modal context
const initialState = {
    isModalOpen: false,
    modalContent: null,
    // ... add more states if needed
};

// Reducer function for the Modal context
const reducer = (state, action) => {
    switch (action.type) {
        case 'OPEN_MODAL':
            return { ...state, isModalOpen: true };
        case 'CLOSE_MODAL':
            return { ...state, isModalOpen: false };
        case 'SET_MODAL_CONTENT':
            return { ...state, modalContent: action.payload };
        // ... handle other actions if needed
        default:
            return state;
    }
};

export const ModalProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const openModal = () => dispatch({ type: 'OPEN_MODAL' });
    const closeModal = () => dispatch({ type: 'CLOSE_MODAL' });
    const setModalContent = content => dispatch({ type: 'SET_MODAL_CONTENT', payload: content });
    return (
        <ModalContext.Provider value={{ ...state, openModal, closeModal, setModalContent }}>
            {children}
        </ModalContext.Provider>
    );
};

// Custom hook to use Modal context
export const useModal = () => {
    const context = useContext(ModalContext);
    if (!context) {
        throw new Error("useModal must be used within ModalProvider");
    }
    return context;
};