import React, { createContext, useReducer, useContext } from 'react';

// Single context for both state and actions.
const PageContext = createContext();

const pageReducer = (state, action) => {
    switch (action.type) {
        case 'SET_PAGE':
            return { ...state, currentPage: action.payload };
        default:
            throw new Error(`Unknown action type: ${action.type}`);
    }
};

export const PageProvider = ({ children }) => {
    const [state, dispatch] = useReducer(pageReducer, { currentPage: 'login' });

    // All page-related functions.
    const handlePageChange = (page) => {
        dispatch({ type: 'SET_PAGE', payload: page });
    };

    // Combine state and actions together.
    const contextValue = {
        currentPage: state.currentPage,
        handlePageChange
    };

    return (
        <PageContext.Provider value={contextValue}>
            {children}
        </PageContext.Provider>
    );
};

export const usePageContext = () => useContext(PageContext);

